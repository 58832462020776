export const commitments = [
  `Recruits high quality talent for engineering roles.`,
  `Provides support to each apprentice through education, community, and reentry services.`,
  `Provides Evolution 1:1 career coaching.`,
  `NC Engineering Director provides technical mentorship and guidance to reduce strain on
  Host Companies’ engineering team.`,
  `NC Reentry Director acts as liaison and mentor between apprentices and company staff.`,
  `Develops cultural engagement opportunities such as speaker series and “lunch and learns” to
  cultivate empathy for this population.`
];


export const responsibilities = [
  `Host NC apprentice at their company for a 5 month onsite apprenticeship.`,
  `Commits to cutural change within their company and supports NC in organizing events.`,
  `Ensures cross-departmental cohesions and support for apprentices’ overall onsite experience.`,
  `Designates a point of contact to coordinate with Next Chapter staff to ensure program success.`,
  `Establishes a variety of mentors to guide apprentices while at the company.`
];
