import news1 from './images/news1.svg';
import news2 from './images/news2.png';
import news3 from './images/news3.jpeg';
import dropboxLogo from './images/dropboxLogo.jpg';
import news5 from './images/news5.jpg';
import computersslack from './images/computersslack.png';
import slackforgood from './images/slackforgood.png';
import nextchapterpanel from './images/nextchapterpanel.png';
import cnbc from './images/cnbc.svg';
import slackfairchance from './images/slackfairchance.svg';
import tidesLeaders from './images/tidesLeaders.svg';
import Dropbox from './images/Dropbox.svg';
import Atlanticlogo from './images/Atlanticlogo.svg';
import lob from './images/lob.svg';
import ten from './images/ten-companies.svg';
import homefree from './images/homefree.jpg';
import fastcompany from './images/jess-fastcompany.jpg';
import cnbc_june29 from './images/cnbc.jpg';
import cnbc_jpmorgan from './images/cnbc_jpmorgan.jpg';


export const Posts = [
  {
    title: `JPMorgan, Microsoft and Slack among companies hiring the formerly incarcerated`,
    author: `CNBC`,
    created: `July 23, 2022`,
    image: cnbc_jpmorgan,
    link: `https://www.cnbc.com/2022/07/23/americans-with-criminal-records-are-in-demand-in-the-job-market.html`
  },
  {
    title: `Slack CEO: There has been a shift in importance from physical to digital`,
    author: `CNBC`,
    created: `June 29, 2022`,
    image: cnbc_june29,
    link: `https://www.cnbc.com/video/2022/06/29/slack-ceo-there-has-been-a-shift-in-importance-from-physical-to-digital-headquarters.html`
  },
  {
    title: `Home/Free documentary shines a light on re-entry barriers for the formerly incarcerated`,
    author: `team at Slack`,
    created: `June 29, 2022`,
    image: homefree,
    link: `https://slack.com/blog/news/home-free-documentary-shines-a-light-on-re-entry-barriers-for-formerly-incarcerated`
  },
  {
    title: `Inside Slack’s bold bet on training formerly incarcerated people to be tech workers`,
    author: `Fast Company`,
    created: `June 29, 2022`,
    image: fastcompany,
    link: `https://www.fastcompany.com/90764677/inside-slacks-bold-bet-on-training-formerly-incarcerated-to-be-tech-workers`
  },
  {
    title: `Three years of removing barriers`,
    author: `team at Slack`,
    created: `March 12, 2021`,
    image: ten,
    link: `https://slack.com/blog/news/slacks-next-chapter-program-reaches-new-milestone`
  },
  {
    title: `Lob joins Slack’s Next Chapter program`,
    author: `Christina Louie Dyer`,
    created: `March 12, 2021`,
    image: lob,
    link: `https://www.lob.com/blog/lob-joins-next-chapter`
  },
  {
    title: `Lifting Tides’ Leaders to Advance Black Collective Power and Equity`,
    author: `Tides`,
    created: 'Aug 2021',
    image: tidesLeaders,
    link: `https://www.linkedin.com/pulse/lifting-tides-leaders-advance-black-collective-power-equity-tides/?trackingId=0xAWJIdAglCW8HD6mJMnJg%3D%3D`,
  },
  {
    title: `How Next Chapter helps formerly incarcerated individuals find a home at Dropbox`,
    author: `Dropbox`,
    created: `Jul 2021`,
    image: Dropbox,
    link: `https://medium.com/life-inside-dropbox/how-next-chapter-helps-formerly-incarcerated-individuals-find-a-home-at-dropbox-b34d854f7c68`,
  },
  {
    title: `Slack’s Fair Chance Hiring Journey: How Starting Small Can Make a Big Impact`,
    author: `Lucretia Murphy`,
    created: `Jun 2021`,
    image: slackfairchance,
    link: `https://www.jff.org/what-we-do/impact-stories/reentry-careers/slacks-fair-chance-hiring-journey-how-starting-small-can-make-a-big-impact/`,
  },
  {
    title: `Slack partners with Next Chapter to help formerly incarcerated find long-term jobs`,
    author: `CNBC`,
    created: `March 2021`,
    image: cnbc,
    link: `https://www.cnbc.com/video/2021/03/12/slack-partners-with-next-chapter-to-help-formerly-incarcerated-find-long-term-jobs.html`,
  },
  {
    title: `Next Chapter aims to reshape society’s perspective of people returning home
            from prison`,
    author: 'Nadia Wynter',
    created: 'October 8th, 2020',
    image: news1,
    link: 'https://slack.com/blog/collaboration/next-chapter-panel-slack-frontiers',
  },
  {
    title: `Next Chapter Panel at Frontiers (key note)`,
    author: `Slack Frontiers`,
    created: `October 2020`,
    image: nextchapterpanel,
    link: `https://www.youtube.com/watch?v=Ajg2PJgzP8Y&feature=emb_title`,
  },
  {
    title: `Dropbox, Zoom join apprenticeship program for the formerly incarcerated`,
    author: 'Katie Clarey',
    created: 'July 20, 2020',
    image: news2,
    link: `https://www.hrdive.com/news/dropbox-zoom-apprenticeship-program-formerly-incarcerated/581916/`,
  },
  {
    title: `A Workforce Reimagined`,
    author: 'Lynne Oldham',
    created: 'July 21, 2020',
    image: news3,
    link: `https://www.linkedin.com/pulse/workforce-reimagined-lynne-oldham-she-her-/?trackingId=FmUoyIPhSmaO3v7l%2BSWmiA%3D%3D`,
  },
  {
    title: `Dropbox joins Slack’s Next Chapter apprenticeship program`,
    author: 'Dropbox Team',
    created: 'July 15, 2020',
    image: dropboxLogo,
    link: `https://blog.dropbox.com/topics/company/dropbox-joins-slack-s-next-chapter-apprenticeship-program`,
  },
  {
    title: `Lessons from Isolation`,
    author: 'Kenyatta Leal',
    created: 'April 7, 2020',
    image: news5,
    link: `https://www.usatoday.com/story/opinion/voices/2020/04/07/coronavirus-forcing-isolation-lessons-ive-learned-san-quentin-can-help-column/5116655002/`,
  },
  {
    title: `What Happens After Prisoners Learn to Code?`,
    author: 'Simone Stolzoff',
    created: 'December 13, 2019',
    image: Atlanticlogo,
    link: `https://www.theatlantic.com/technology/archive/2019/12/from-prison-to-silicon-valley/603406/`,
  },
  {
    title: `The Next Chapter program: A collaboration for impact`,
    author: 'the team at Slack',
    created: 'November 20th, 2019',
    image: computersslack,
    link: `https://slack.com/blog/news/the-next-chapter-program-a-collaboration-for-impact`,
  },
  {
    title: `Big Tech's Newest Experiment in Criminal-Justice Reform`,
    author: 'Alexis C. Madrigal',
    created: 'October 8th, 2020',
    image: Atlanticlogo,
    link: `https://www.theatlantic.com/technology/archive/2018/08/big-techs-newest-experiment-in-criminal-justice-reform/568849/`,
  },
  {
    title: `Helping formerly incarcerated individuals find work and succeed in tech`,
    author: 'the team at Slack',
    created: 'October 8th, 2020',
    image: slackforgood,
    link: `https://slack.com/blog/news/next-chapter-a-pilot-program-aiming-to-help-formerly-incarcerated-individuals-find-work-and-succeed-in-tech`,
  }
];
