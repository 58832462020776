import React from 'react';
import styles from './Profile.module.css';

interface IProps {
  name: string;
  title: string;
  image: string;
  link: string;
}


const Profile: React.FC<IProps> = ({ name, title, image, link }) => {
  return (
    <figure className={ styles.container }>
        <img src={image} alt="profile-pic" />
        <figcaption className={ styles.info }>
          <h4>{ name }</h4>
          <p>{ title }</p>
          <a href={ link } target="_blank" rel="noopener noreferrer">
            <i className={ `${styles.icons} icon ion-social-linkedin`}></i>
          </a>
        </figcaption>
    </figure>
  );
}

export default Profile;
