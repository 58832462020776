import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Footer.module.css';

import { APPLY_FORM_URL } from '../constants';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <section className={ styles.topContainer }>
        <section className={ styles.pageLinks }>
          <ul>
            <li>
              <NavLink exact activeClassName="" to="/">
                 Home
              </NavLink>
            </li>
            <li>
              <NavLink to='/about' activeClassName="">
                About
              </NavLink>
            </li>
            <li>
              <NavLink to='/news' activeClassName="">
                Blog
              </NavLink>
            </li>
          </ul>
        </section>
        <section className={ styles.links }>
          <ul>
            <li>
              <a href="https://donatenow.networkforgood.org/1442917"
                 target="_blank" rel="noopener noreferrer">
                Donate
              </a>
            </li>
            <li>
              <a href={APPLY_FORM_URL}
                 target="_blank" rel="noopener noreferrer">
               Apply
              </a>
            </li>
            <li>
              <NavLink  activeClassName="" to='/contact'>
                Get Involved
              </NavLink>
            </li>
          </ul>
        </section>
        <section className={ styles.companyInfo }>
          <h3>Next Chapter </h3>
          <p>
            Transforming the tech sector to be fair, inclusive, and equitable by
            providing career pathways for people leaving incarceration.
          </p>
        </section>
      </section>
      <section className={ styles.social }>
        <a href="https://www.linkedin.com/company/next-chapter-project" target="_blank"
          rel="noopener noreferrer">
          <i className={ `icon ion-social-linkedin ${styles.icons}` }></i>
        </a>
        <a href="mailto:info@nextchapterproject.org">
          <i className={ `ion-email ${ styles.icons }` }></i>
        </a>
      </section>
      <section className={ styles.copyrightContainer }>
        <p className="copyright"> Next Chapter © 2021 </p>
      </section>
    </footer>
  );
}

export default Footer;
