import React, { useState } from 'react';
import { RiMenu3Line } from 'react-icons/ri';
import { CgClose } from 'react-icons/cg';
import styles from '../../css/NavBar.module.css';
import NavLinks from './NavLinks';

const MobileNavigation = () => {
  const [open, setOpen] = useState(false);

  const handleClick = (action: boolean) => {
    setOpen(!action);
  };

  const openIcon = (
    <RiMenu3Line
      className={styles.hamburger}
      size="35px"
      onClick={() => setOpen(!open)}
    />
  );

  const closeIcon = (
    <>
      <CgClose
        className={styles.hamburger}
        size="35px"
        onClick={() => setOpen(!open)}
      />
      <NavLinks isMobile={ true } open={ open } handleClick={ handleClick } />
    </>
  );

  return (
    <nav className={styles.mobileNavigation}>
      {!open ? openIcon : closeIcon}
    </nav>
  );
};

export default MobileNavigation;
