import React, { useState } from 'react';
import { commitments, responsibilities } from '../programInfo';
import ProgramInfoItems from './ProgramInfoItems';
import styles from './ProgramInfo.module.css';


const ProgramInfo = () => {
  const [showSecondTab, setShowSecondTab] = useState(false);

  const handleCommitmentsClick = () => {
    if(showSecondTab) {
      setShowSecondTab(!showSecondTab);
    }
  }

  const handleResponsibilitiesClick = () => {
    if(!showSecondTab) {
      setShowSecondTab(!showSecondTab);
    }
  }

  return (
    <section className={styles.container}>
      <section className={styles.buttoncontainer}>
        <button onClick={ handleCommitmentsClick }
        className={ !showSecondTab ? styles.clicked : styles.button }>
          Next Chapter Program Commitments
        </button>
        <button onClick={ handleResponsibilitiesClick }
        className={ showSecondTab ? styles.clicked : styles.button }>
          Partner Company Responsibilities
        </button>
      </section>
      <section className={styles.items}>
        {showSecondTab ? <ProgramInfoItems items={ responsibilities } /> :
        <ProgramInfoItems items={ commitments } /> }
      </section>
    </section>
  );
}

export default ProgramInfo;
