import React from 'react';


const ShowNotification = (
  {
    hasError,
    notification,
  }: {
    hasError: boolean,
    notification: string,
  }
) => {
  const style = {
    backgroundColor: hasError ? 'lightred': 'lightgreen',
    opacity: 0.5,
    padding: 10,
    color: 'black',
  }


  if (!notification) return null;

  return (
    <section style={style}>
      {notification}
    </section>
  );
};

export default ShowNotification;
