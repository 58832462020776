import React from 'react';
import  styles from './InformationIcons.module.css';

interface IProps {
  icon: string;
  title: string;
  paragraph: string;
}

const InformationIcons: React.FC<IProps> = ({ icon, title, paragraph }) => {
  return (
    <div className={styles.container}>
      <div><i className={ `${icon} ${styles.icons}` }></i></div>
      <div className={ styles.text }>
        <h4>{ title }</h4>
        <p>{ paragraph }</p>
      </div>
    </div>
  );
}

export default InformationIcons;
