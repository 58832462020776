import React from 'react';
import styles from './ProgramInfoItems.module.css';

interface IProps {
  items: string[];
}


const ProgramInfoItems: React.FC<IProps> = ({ items }) => {
  return (
    <div className={styles.container}>
      { items.map(item => (
        <div key={item} className={styles.item}>
          <i className={`ion-checkmark ${styles.checkmark}`}></i>
          <div className={ styles.text }><span>{ item }</span></div>
        </div>
      ))}
    </div>
  );
}

export default ProgramInfoItems;
