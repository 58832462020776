import dawn from './images/dawn.jpeg';
import kenyatta from './images/kenyatta.jpg';
import david from './images/david.jpg';
import cynthia from './images/cynthia.jpg';
import priya from './images/priya.jpg';
import aly from './images/aly.jpg';
import britannia from './images/britannia.png';
import talia from './images/talia.png';
import dan1 from './images/dan1.png';


export const team = [
  {
    name: 'Kenyatta Leal',
    title: 'Executive Director',
    image: kenyatta,
    link: 'http://linkedin.com/in/chrisfino-kenyatta-leal-92650080',
  },
  {
    name: 'Dan Wheeler',
    title: 'Engineering Director',
    image: dan1,
    link: 'http://linkedin.com/in/danlowewheeler',
  },
  {
    name: 'Britannia Bloom',
    title: 'Partnerships Director',
    image: britannia,
    link: 'http://linkedin.com/in/britanniabloom',
  },
  {
    name: 'Talia Klutch',
    title: 'Project Coordinator',
    image: talia,
    link: 'http://linkedin.com/in/talia-klutch',
  },
];

export const advisory = [
  {
    name: 'David Schellhase',
    title: 'Former General Counsel, Slack',
    image: david,
    link: 'https://www.linkedin.com/in/david-schellhase-209391/',
  },
  {
    name: 'Cynthia Muller',
    title: 'Director, Mission Driven Investment, W.K. Kellogg Foundation',
    image: cynthia,
    link: 'https://www.linkedin.com/in/cynthiamuller/',
  },
  {
    name: 'Priya Chordia',
    title: 'Co-Head, Social Impact Practice; EVP, Operations and Growth, Propper Daley',
    image: priya,
    link: 'https://www.linkedin.com/in/priya-chordia-76b1bb5/',
  },
  {
    name: 'Kenyatta Leal',
    title: 'Executive Director, Next Chapter',
    image: kenyatta,
    link: 'http://linkedin.com/in/chrisfino-kenyatta-leal-92650080',
  },
  {
    name: 'Aly Tamboura',
    title: 'Associate Program Lead, The Just Trust',
    image: aly,
    link: 'https://www.linkedin.com/in/aly-tamboura/',
  },
  {
    name: 'Dan Wheeler',
    title: 'Engineering Director, Next Chapter',
    image: dan1,
    link: 'http://linkedin.com/in/danlowewheeler',
  },
  {
    name: 'Dawn Sharifan',
    title: 'Former Head of People, Slack',
    image: dawn,
    link: `https://www.linkedin.com/in/dawnsharifan/`,
  },
];
