import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../../css/NavBar.module.css';

interface IProps {
  isMobile: boolean;
  open: boolean;
  handleClick: (action: boolean) => void;
}

const NavLinks = ({ isMobile, open, handleClick }: IProps) => {
  const handleClose = () => {
    if(isMobile) {
      handleClick(open);
    }
  }
  return (
    <ul>
      <li>
        <NavLink exact className={ styles.navLink }
          activeClassName={ styles.active } to="/" onClick={handleClose}>
          HOME
        </NavLink>
      </li>
      <li>
        <NavLink className={ styles.navLink } activeClassName={ styles.active }
          to='/about' onClick={handleClose}>
          ABOUT
        </NavLink>
      </li>
      <li>
        <NavLink className={ styles.navLink } activeClassName={ styles.active }
          to='/news' onClick={handleClose}>
          IN THE NEWS
        </NavLink>
      </li>
      <li>
        <NavLink className={ styles.navLink } activeClassName={styles.active}
          to='/contact' onClick={handleClose}>
          GET INVOLVED
        </NavLink>
      </li>
      <li>
        <a href="https://donatenow.networkforgood.org/1442917"
          target="_blank" rel="noopener noreferrer" className={ styles.navLink }
          onClick={ handleClose }>
          DONATE
        </a>
      </li>
    </ul>
  );
}

export default NavLinks;
