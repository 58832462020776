import React from 'react';
import styles from '../../css/NavBar.module.css';
import NavLinks from './NavLinks';

const Navigation = () => {
  const handleClick = (action: boolean) => {
    console.log(action);
  }

  return (
    <nav className={styles.navigation}>
      <NavLinks isMobile={false} open={false} handleClick={handleClick}/>
    </nav>
  );
};

export default Navigation;
