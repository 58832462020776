import React from 'react';
import { Stories } from '../carouseldata';
import styles from './Story.module.css';


const  Story = () => {
  return (
    <section className={ styles.container }>
      { Stories.map(story => (
        <section key={ story.title } className={ styles.story }>
          <section className= {styles.storyText } >
            <h4>{ story.title }</h4>
            { story.info }
          </section>
          <section className={ styles.storyImgContainer }>
            <img src={ story.image } alt="Students Coding"  />
          </section>
        </section>
      ))}
    </section>
  );
}

export default Story;
