import React from  'react';
import logo from '../../images/logo.svg';
import styles from '../../css/Logo.module.css';


const Logo = () => {
  return (
    <div className={ styles.logo }>
      <img src={logo} alt="Next Chapter Logo" />
    </div>
  );
}

export default Logo;
