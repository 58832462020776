import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Logos } from '../carouseldata';
import styles from './CompanyCarousel.module.css';
import '../css/CompanyCarousel.css';


const CompanyCarousel = () => {
  return (
    <div className={ styles.container }>
      <Carousel width={"90vw"} showThumbs={ false } showArrows={ false }
        infiniteLoop autoPlay interval={ 5000 }>
        { Logos.map((logo, idx: number) => (
            <div key={ idx } className={ styles.imagecontainer }>
               <a  target="_blank" rel="noopener noreferrer" href={ logo.link } >
                  <img src={logo.image} alt="logo" id={ styles.image }/>
                </a>
            </div>
          ))}
      </Carousel>
    </div>
  );
}

export default CompanyCarousel;
