import React from 'react';
import styles from './NewsItems.module.css';

interface IProps {
  title: string;
  description: string;
  link: string;
  image: string;
}

const NewsItem: React.FC<IProps> = ({  title, description, link, image }) => {
  return (
    <section className={ styles.container }>
      <figure className={ styles.imageContainer }>
        <img src={image} alt="news-article"/>
        <figcaption className={ styles.info }>
          <h4>{ title }</h4>
          <p>by { description }</p>
          <a target="_blank" rel="noopener noreferrer" href={ link }>Read more</a>
        </figcaption>
      </figure>
    </section>
  );
}

export default NewsItem;
