import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import MobileNavigation from './MobileNavigation';
import styles from '../../css/Header.module.css';

const Header = ()  => {

  return (
    <header className={ styles.header }>
      <Logo />
      <Navigation />
      <MobileNavigation />
    </header>
  );
}

export default Header;
