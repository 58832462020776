import React, { useEffect } from 'react';
import { Posts } from '../blogPosts';
import NewsItem from './NewsItem';
import styles from './News.module.css';


const News = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={styles.container }>
      <section className={ styles.heading }>
        <h2>In The News</h2>
      </section>
      <section className={styles.newscontainer}>
        { Posts.map(post => (
          <NewsItem key={post.title} title={post.title} description={post.author}
            link={post.link} image={post.image} />
          ))}
      </section>
    </section>
  );
}

export default News;
