import React from 'react';
import './css/App.css';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Header from './components/Header/index';
import Footer from './components/Footer';
import Contact from './components/Contact';
import News from './components/News';
import Apply from './components/Apply';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Route exact path='/' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/news' component={News} />
        <Route path='/contact' component={Contact} />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
