import * as React from 'react';

import kellogg from './images/kellogg.svg';
import slacklogo from './images/slacklogo.svg';
import concreterose from './images/concreterose.svg';
import tides from './images/tides.svg';
import lastmile from './images/lastmile.svg';
import czi from './images/czi.svg';
import omidyar from './images/omidyar.svg';
import autocad from './images/autocad.jpg';
import atlantic from './images/atlantic.png';
import slackclass from './images/slackclass.jpg';
import Office from './images/Office.jpg';


export const Logos = [
  {
    image: kellogg,
    link: 'https://www.wkkf.org/',
  },
  {
    image: slacklogo,
    link: 'https://slack.com/',
  },
  {
    image: concreterose,
    link: 'https://concreterosecapital.com/',
  },
  {
    image: tides,
    link: 'https://www.tides.org/',
  },
  {
    image: lastmile,
    link: 'https://thelastmile.org/',
  },
  {
    image: czi,
    link: 'https://chanzuckerberg.com/',
  },
  {
    image: omidyar,
    link: 'https://omidyar.com/',
  },
];

interface Story {
  title: string;
  info: JSX.Element,
  image: string;
}

export const Stories: Array<Story> = [
  {
    title: `In The Beginning`,
    info: (
      <p>
        In 2018, Slack, The Last Mile, W.K. Kellogg, and FREEAMERICA, co-created Next Chapter, a pilot program designed to shift perceptions about the potential of formerly incarcerated individuals and to generate new opportunities in skilled, long-term employment in the technology sector.
      </p>
    ),
    image: autocad,
  },
  {
    title: `The First Cohort`,
    info: (
      <p>
        After eight months of rigorous training, one-on-one coaching/mentoring and successfully completing the Next Chapter program, the three apprentices in the first cohort are now full-time Slack engineers. They are valuable members of the Slack team and their unique experiences have contributed to helping build a robust product and an even more inclusive culture.
      </p>
    ),
    image: slackclass,
  },
  {
    title: `The Next Chapter`,
    info: (
      <>
        <p>
          Since the success of the original pilot program, Next Chapter has expanded to 14 hiring partner companies. More than 30 individuals have successfully graduated from the program and received full-time employment offers from companies in our network of hiring partners, which includes leaders like Zoom, Dropbox and Square. Today, Next Chapter is a fiscally sponsored project of Tides Center.
        </p>
        <p>
          We are continuing to scale and build more opportunities in the tech sector for those returning from incarceration. We’re proud that Slack and the Aspen Institute are contributing to this effort through a new initiative called <a href="http://www.reworkreentry.org/">Rework Reentry.</a> 
        </p>
      </>
    ),
    image: Office,
  },
  {
    title: `The Cultural Shift`,
    info: (
      <p>
        Our mission is two fold; we aim to build a pathway for formerly incarcerated individuals to thrive in tech and transform the sector into a more inclusive, equitable workplace by shifting perceptions of those returning from incarceration. We believe that talent is equally distributed. With the right training, we hope to provide opportunities for those who would not only thrive at these large tech companies, but also fill the massive shortage of engineering talent that the tech community needs from an often-overlooked population.
      </p>
    ),
    image: atlantic,
  }
];
