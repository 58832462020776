import React, { useEffect } from 'react';
import Button from './Button';
import Profile from './Profile';
import styles from './About.module.css';
import ProgramInfo from './ProgramInfo';
import Story from './Story';
import { team, advisory } from '../People';

//images
import graduationdiploma from '../images/graduationdiploma.jpg';



const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section id={ styles.aboutContainer }>
      <section className={ styles.topSection }>
        <section className={ styles.mission }>
          <section className= {styles.missionText } >
            <h1>Our Mission</h1>
            <p>
              Transforming the technology sector by creating a more equitable workplace
            for formerly incarcerated individuals.
            </p>
              <a href="https://donatenow.networkforgood.org/1442917"
                 target="_blank" rel="noopener noreferrer">
                <button className={ styles.donate }> Donate Today </button>
              </a>
          </section>
          <section className={ styles.imageContainer }>
            <img src={ graduationdiploma } alt="mission" />
          </section>
        </section>
        <div><h1>Our Story...</h1></div>
        <Story />
      </section>
      <section className={ styles.upperMidSection }>
        <h1>How the Next Chapter Works</h1>
        <ProgramInfo />
      </section>
      <section className={ styles.midSection }>
        <h1>Our Team</h1>
        <section className={styles.teamContainer}>
          { team.map(member => (
            <Profile key={member.name} name={member.name}
            title={member.title} image={member.image} link={member.link} />
          ))}
        </section>
      </section>
      <section className={ styles.lowerMidSection }>
        <h1>Our Advisory Board</h1>
        <section className={styles.teamContainer}>
          { advisory.map(member => (
            <Profile key={member.name} name={member.name}
            title={member.title} image={member.image} link={member.link} />
          ))}
        </section>
      </section>
      <section className={ styles.lastSection }>
        <p>Are you Interesting in Working With Us?</p>
        <Button link={'/contact'} buttonName={'GET INVOLVED'} name={ styles.button }/>
      </section>
    </section>
  );
}

export default About;
