import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import InformationIcons from './InformationIcons';
import NewsItem from './NewsItem';
import { Posts } from '../blogPosts';
import CompanyCarousel from './CompanyCarousel';
import styles from './Home.module.css';

const topPosts = Posts.slice(0, 3);


const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id={ styles.homeContainer }>
      <section className={ styles.firstSection }>
        <div className={ styles.firstSectionContents }>
          <div className={ styles.mainText }>
            <h1>Next Chapter</h1>
            <p>
              Transforming the technology sector by creating a more equitable workplace
              for formerly incarcerated individuals.
            </p>
          </div>
          <div className={ styles.buttons }>
            <Button link={'/about'} buttonName={'LEARN MORE'} name={styles.topButton}/>
            <Button link={'/contact'} buttonName={'GET INVOLVED'} name={ styles.topButton }/>
          </div>
        </div>
      </section>
      <section className={ styles.carouselSection }>
        <CompanyCarousel />
      </section>
      <section className={ styles.midSection }>
        <div className={ styles.text }>
          <h1>Talent is Everywhere. Opportunity is Not.</h1>
          <p>
            In 2018, Next Chapter was established in partnership with { }
            <a
             href="https://www.slack.com" target="_blank"
              rel="noreferrer">
             Slack</a> to create pathways for
            formerly incarcerated individuals to obtain gainful employment in the technology
            sector.
            </p>
            
            <p>
              In our original pilot, three formerly incarcerated individuals went through an eight month engineering apprenticeship program at Slack. After the conclusion of the program, the three apprentices were interviewed and hired by Slack as full time engineers. Since then, Next Chapter has expanded to 14 hiring partner companies. More than 30 individuals have successfully graduated from the program and received full-time employment offers from companies in our network of hiring partners.
            </p>
            
            <p>
            Together with our partners, Next Chapter is helping to break down the stigma surrounding those with a criminal record and effectively creating new workforce opportunities for the reentry community.  
            </p>
        </div>
        <div className={ styles.video }>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/L0N2iTWnjSo"
          title="YouTube video player" frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;
          picture-in-picture" allowFullScreen>
          </iframe>
        </div>
      </section>
      <section className={ styles.lowerSection }>
        <section className={ styles.text }>
          <h1>How Next Chapter Works</h1>
        </section>
        <section className={ styles.iconsContainer }>
          <InformationIcons
          icon={"ion-clipboard"}
          title={"Recruitment"}
          paragraph={"Next Chapter sources candidates from community, non-profit, and education partners. Candidates are selected through a six-step rigorous interview process."}
          />
          <InformationIcons
          icon={"ion-university"}
          title={"Education"}
          paragraph={"After candidates are selected, apprentices receive a stipend and scholarship to attend the software engineering immersion program at Hack Reactor."}
          />
          <InformationIcons
          icon={"ion-briefcase"}
          title={"Real-World Onsite Experience"}
          paragraph={"After successful completion of Hack Reactor, candidates are placed with their host company for an onsite engineering apprenticeship."}
          />
          <InformationIcons
          icon={"ion-android-compass"}
          title={"Cultural Onboarding"}
          paragraph={"Next Chapter staff supports individual apprentices through mentorship and wraparound services. Next Chapter also supports host companies to ensure departments are equipped to support individuals returning from incarceration."}
          />
        </section>
      </section>
      <section className={ styles.bottomSection }>
        <div className={ styles.text }>
          <h1>Become a Next Chapter Employer</h1>
          <p>
            Next Partner partners with a number of top-tier tech companies.
            <Link to='/about' className={styles.link}>{" "}Learn more about partnership requirements.</Link>
          </p>
        </div>
        <div className={ styles.buttons }>
          <Button link={'/about'} buttonName={'LEARN MORE'} name={styles.topButton}/>
        </div>
      </section>
      <section className={ styles.lastSection }>
        <div className={ styles.text }><h1>In The News</h1></div>
        <div className={ styles.newsItemContainer }>
          { topPosts.map(post => (
            <NewsItem key={post.title} title={post.title} description={post.author}
              link={post.link} image={post.image} />
            ))}
        </div>
        <div className={ styles.buttons }>
          <Button link={'/news'} buttonName={'MORE NEWS'} name={ styles.topButton }/>
          <Button link={'/contact'} buttonName={'GET INVOLVED'} name={ styles.topButton }/>
        </div>
      </section>
    </div>
  );
}

export default Home;
