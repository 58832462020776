import React, { useState, useEffect, FormEvent } from "react";
import styles from "./Contact.module.css";
import ShowNotification from "./ShowNotification";

const Contact = () => {
  const [input, setInput] = useState({
    name: "",
    email: "",
    reason: "",
    subject: "",
    message: "",
  });
  const reasons = [
    "I'm justice impacted and interested in applying",
    "Having my company become a hiring partner",
    "Collaborating as a nonprofit/public partner",
    "Volunteering",
    "I'm interested in becoming a donor/funder",
    "Other"
  ];

  const [hasError, setHasError] = useState(false);
  const [notification, setNotification] = useState("");

  const handleNotification = (message: string, hasError = false) => {
    setNotification(message);
    setHasError(hasError);
    removeNotification(5000);
  };

  const removeNotification = (time: number) => {
    setTimeout(() => {
      setNotification("");
      setHasError(false);
    }, time);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = { ...input };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    fetch("/api/contact", requestOptions)
      .then((response) => response)
      .then((_) => {
        handleNotification("Thank you for your message.");
        setInput({
          name: "",
          email: "",
          reason: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        handleNotification(
          "Sorry unable to deliver your message. Please try again",
          true
        );
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className={styles.container}>
        <section className={styles.title}>
          <h2>{`Get Involved`}</h2>
          <ShowNotification hasError={hasError} notification={notification} />
        </section>
        <section className={styles.inputContainer}>
          <label htmlFor="name">
            Name<span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="name"
            required
            maxLength={60}
            placeholder="Name"
            onChange={handleChange}
            className={styles.textInput}
            value={input.name}
          />
        </section>
        <section className={styles.inputContainer}>
          <label htmlFor="reason">
            Which best describes your reason for reaching out?<span className={styles.required}>*</span>
          </label>
          <select
            name="reason"
            required
            onChange={handleChange}
            className={styles.textInput}
            value={input.reason}
          >
            <option value="">Select a reason</option>
            {reasons.map((reason) => (
              <option key={reason} value={reason}>
                {reason}
              </option>
            ))}
          </select>
        </section>
        <section className={styles.inputContainer}>
          <label htmlFor="email">
            Email<span className={styles.required}>*</span>
          </label>
          <input
            type="email"
            name="email"
            size={30}
            maxLength={60}
            required
            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            placeholder="Email"
            onChange={handleChange}
            className={styles.textInput}
            value={input.email}
          />
        </section>
        <section className={styles.inputContainer}>
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            name="subject"
            size={30}
            maxLength={60}
            placeholder="Subject"
            onChange={handleChange}
            className={styles.textInput}
            value={input.subject}
          />
        </section>
        <section className={styles.inputContainer}>
          <textarea
            rows={3}
            name="message"
            id="body"
            maxLength={180}
            value={input.message}
            placeholder="Enter your message..."
            onChange={handleChange}
            className={styles.textArea}
          />
        </section>
        <section className={styles.submitContainer}>
          <button type="submit" className={styles.submit}>
            Send Message
          </button>
        </section>
      </form>
    </div>
  );
};

export default Contact;
