import React from 'react';
//import { Link } from 'react-router-dom';
//import '../css/Button.css';

interface IProps {
  link: string;
  buttonName: string;
  name: string;
}

const Button: React.FC<IProps> = ({link, buttonName, name}) => {
  return (
    <a href={link}>
      <button className={name}>{buttonName}</button>
    </a>
  );
}

export default Button;
